:root {
	--color-primary: #EA345C;
	--color-primary-light: #F73660;
	--color-primary-dark: #AB2642;
	--color-resalt: #168CB8;
	--color-grey-light-1: #faf9f9;
	--color-grey-light-2: #f4f2f2;
	--color-grey-light-3: #f0eeee;
	--color-grey-light-4: #cccccc;
	--color-grey-dark-1: #404040;
	--color-grey-dark-2: #777;
	--color-grey-dark-3: #999;
	--color-red: #A52631;
	--color-green: #207245;
	--shadow-dark: 0 2rem 6rem rgba(0, 0, 0, 0.3);
	--shadow-light: 0 2rem 5rem rgba(0, 0, 0, 0.06);
	--line: 1px solid var(--color-grey-light-2);
}
* {
	margin: 0 ;
	padding: 0 ;
}
*,
*::before,
*::after {
	box-sizing: inherit;
}
html {
	box-sizing: border-box;
	font-size: 62.5%; 
}
@media only screen and (max-width: 68.75em) {
	html {
		font-size: 50%;
	} 
}
body {
	font-weight: 400;
	line-height: 1.6;
	color: var(--color-grey-dark-2);
	background-color: var(--color-grey-light-3);
	background-size: cover;
	background-repeat: no-repeat;
	min-height: 100vh;
	font-family: 'Montserrat', sans-serif;
}

/* APP */
header {
	display: flex;
	background-color: var(--color-primary);
	height: 6rem;
	position: fixed;
	width: 100%;
	box-shadow: 1px 1px 3px rgba(0, 0, 0, 0.3);
	z-index: 99;
}
.header__container {
	width: 100%;
	max-width: 120rem;
	margin: 0 auto;
	display: flex;
}
.header__container > a {
	padding: 1rem;
	display: flex;
	align-items: center;
	justify-content: center;
}
.header__container > a img {
	height: 4rem;
}
.header__container ul {
	flex: 0 0 85%;
	display: flex;
	margin-left: auto;
	list-style: none;
}
.header__container ul li {
	display: flex;
	flex-direction: column;
	padding: 0 1rem;
	position: relative;
	align-items: center;
	flex: 1;
}
.header__container ul > a {
	height: 6rem;
	color: #fff;
	position: relative;
	display: flex;
	align-items: center;
	font-size: 1.4rem;
	text-decoration: none;
	padding: 2rem;
	text-transform: uppercase;
	background-color: var(--color-resalt);
	font-weight: 700;
	text-align: center;
	letter-spacing: 1px;
}
.header__container ul li > a {
	height: 6rem;
	color: #fff;
	position: relative;
	display: flex;
	align-items: center;
	font-size: 1.4rem;
	text-decoration: none;
	padding: 2rem;
	text-transform: uppercase;
}
.header__container ul li > a:hover {
	color: rgba(255, 255, 255, .7);
}
.header__container ul li > .options {
	position: absolute;
	top: 6rem;
	width: 100%;
	background-color: #fff;
	padding: 1rem;
	flex-direction: column;
	display: none;
	border-radius: 1rem;
	box-shadow: 2px 2px 4px rgba(0, 0, 0, 0.3);
}
.header__container ul li:hover > .options {
	display: flex;
}
.header__container ul li > .options a {
	font-size: 1.4rem;
	margin: 1rem;
	color: var(--color-primary);
	text-decoration: none;
	text-transform: uppercase;
}
.header__container ul li > .options a:hover {
	color: var(--color-primary-light);
}
footer {
	display: flex;
	flex-direction: column;
}
.footer__top {
	background-color: var(--color-primary);
	display: flex;
}
.footer__top-container {
	width: 100%;
	max-width: 120rem;
	margin: 0 auto;
	padding: 9rem 0;
	display: flex;
}
.footer__top-container picture {
	padding: 1rem;
	display: flex;
	align-items: flex-start;
	justify-content: flex-start;
}
.footer__top-container picture img {
	width: 4rem;
}
.footer__top-container ul {
	display: flex;
	flex-direction: column;
	flex: 0 0 20%;
}
.footer__top-container ul a {
	font-size: 1.4rem;
	color: #fff;
	padding: 1rem;
	margin: .5rem;
	text-decoration: none;
}
.footer__top-container ul a:hover {
	color: rgba(255, 255, 255, .7);
}
.footer__social {
	display: flex;
	flex-direction: column;
}
.footer__social h3 {
	font-size: 2rem;
	color: #fff;
}
.footer__social-list {
	display: flex;
	justify-content: space-around;
}
.footer__social > p {
	color: rgba(255, 255, 255, .6);
	font-size: 1.4rem;
	margin-top: 1rem;
}
.footer__social > span {
	color: #fff;
	font-size: 1.4rem;
	margin-top: 1rem;
}
.footer__bottom {
	padding: 2rem;
	display: flex;
	background-color: var(--color-primary-dark);
}
.footer__bottom-container {
	width: 100%;
	max-width: 120rem;
	margin: 0 auto;
	color: #fff;
}
.footer__bottom-container ul {
	display: flex;
	list-style: none;
	justify-content: center;
	font-size: 1.2rem;
}
.footer__bottom-container ul a {
	text-decoration: none;
	color: #fff;
	margin-left: 1.5rem;
}
.footer__bottom-container p {
	text-align: center;
	margin-top: 2.5rem;
}
.cover {
	height: 70vh;
	background-size: cover;
	background-position: center;
	display: flex;
}
.cover__container {
	width: 100%;
	max-width: 120rem;
	margin: 0 auto;
	height: 100%;
	display: flex;
	align-items: center;
}
.cover__container .content {
	flex: 0 0 40%;
	display: flex;
	flex-direction: column;
}
.cover__container .content h1 {
	font-size: 4.5rem;
	text-transform: uppercase;
	color: #fff;
	letter-spacing: 3px;
}
.cover__container .content p {
	font-size: 1.4rem;
	margin-top: 2rem;
	color: #fff;
	text-align: justify;
}
.cover__container .content a {
	padding: 1rem 2rem;
	background-color: var(--color-resalt);
	margin-top: 2rem;
	border-radius: 5px;
	margin-right: auto;
	color: #fff;
	font-size: 1.4rem;
	text-transform: uppercase;
	text-decoration: none;
	font-weight: 600;
}

/* HOME */
.home {
	min-height: 100vh;
}
.home__cover {
	display: flex;
	height: 90vh;
	background-image: url('/images/m1.png');
	background-size: cover;
}
.home__cover-container {
	width: 100%;
	max-width: 120rem;
	margin: 0 auto;
	display: flex;
	align-items: center;
}
.home__cover-container .content {
	width: 100%;
	max-width: 50rem;
	display: flex;
	flex-direction: column;
}
.home__cover-container .content h1 {
	color: #fff;
	font-size: 4.5rem;
}
.home__cover-container .content p {
	font-size: 1.4rem;
	color: #fff;
	margin-top: 1rem;
}
.home__cover-container .content a {
	padding: 1rem 2rem;
	margin-top: 2.5rem;
	border-radius: .5rem;
	background-color: var(--color-resalt);
	color: #fff;
	margin-right: auto;
	font-size: 1.4rem;
	text-decoration: none;
	font-weight: 700;
}
.home__download {
	padding: 9rem 0;
	display: flex;
}
.home__download-container {
	width: 100%;
	max-width: 120rem;
	margin: 0 auto;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
}
.home__download-container span {
	font-size: 3.5rem;
	font-weight: 700;
	color: var(--color-primary);
	text-align: center;
}
.home__download-container ul {
	margin-top: 2.5rem;
}
.home__section {
	display: flex;
	padding: 4.5rem 0;
}
.home__section-container {
	display: flex;
	width: 100%;
	max-width: 100rem;
	margin: 0 auto;
}
.home__section-container .content {
	flex: 2;
	display: flex;
	flex-direction: column;
	justify-content: center;
}
.home__section-container .content h3 {
	font-size: 2.5rem;
	text-transform: uppercase;
	color: var(--color-grey-dark-1);
	letter-spacing: 3px;
}
.home__section-container .content p {
	font-size: 2rem;
	margin-top: 1rem;
}
.home__section-container .content a {
	margin-top: 2rem;
	padding: 1rem 2rem;
	background-color: var(--color-resalt);
	color: #fff;
	margin-right: auto;
	font-size: 1.4rem;
	text-decoration: none;
	border-radius: 5rem;
}
.home__section-container picture {
	flex: 1;
	justify-content: center;
	align-items: center;
	display: flex;
}
.home__section-container picture img {
	width: 100%;
}
.home__image-section {
	display: flex;
}
.home__image-section-container {
	width: 100%;
	max-width: 120rem;
	margin: 0 auto;
}
.home__image-section-container img {
	width: 100%;
}

/* CUENTAS */
.cuentas__list {
	padding: 12rem 0;
	display: flex;
	background-color: #fff;
}
.cuentas__list-container {
	width: 100%;
	max-width: 120rem;
	margin: 0 auto;
}
.cuentas__list-container ul {
	list-style: none;
	display: flex;
	flex-wrap: wrap;
}
.cuentas__list-container ul li {
	padding: 2.5rem;
	display: flex;
	flex-direction: column;
	flex: 0 0 50%;
}
.cuentas__list-container ul li h3 {
	font-size: 2rem;
	text-align: center;
	color: var(--color-primary);
	text-transform: uppercase;
}
.cuentas__list-container ul li p {
	font-size: 1.4rem;
	margin-top: 2rem;
	text-align: justify;
}
.cuentas__list-container ul li a {
	margin-top: 1.5rem;
	padding: .5rem;
	text-align: center;
	font-size: 1.4rem;
	text-decoration: none;
	text-transform: uppercase;
	color: var(--color-resalt);
	font-weight: 600;
	border-radius: 5rem;
	transition: all .3s;
	margin-left: auto;
	display: flex;
	align-items: center;
}
.cuentas__list-container ul li a i {
	transition: all .3s;
}
.cuentas__list-container ul li a:hover i {
	margin-left: .5rem;
}
.cuentas__highlights {
	padding: 9rem 0;
	display: flex;
}
.cuentas__highlights-container {
	display: flex;
	width: 100%;
	max-width: 120rem;
	margin: 0 auto;
}
.cuentas__highlights-container .item {
	flex: 1;
	margin: 2rem;
	display: flex;
	flex-direction: column;
}
.cuentas__highlights-container .item h4 {
	font-size: 2rem;
	color: var(--color-grey-dark-1);
	margin-top: 1rem;
}
.cuentas__highlights-container .item p {
	font-size: 1.4rem;
	margin-top: 1rem;
}

/* LOANS */
.loans {
	height: 100vh;
	display: flex;
	justify-content: center;
	align-items: center;
}

.overdraft {
	height: 100vh;
}
.overdraft form {
	margin-top: 9rem;
}

/* LOGIN */
.login {
	min-height: 100vh;
	display: flex;
	justify-content: center;
	align-items: center;
	background-color: #fff;
}
.login__container {
	width: 100%;
	max-width: 40rem;
	padding: 2.5rem;
	border: 1px solid var(--color-grey-light-4);
	border-radius: 5px;
}
.login__container h1 {
	font-size: 2.5rem;
	text-align: center;
	margin-bottom: 2rem;
	color: var(--color-primary);
}
.login__container p {
	font-size: 1.4rem;
	text-align: right;
}
.login__container a {
	color: var(--color-primary);
}

/* DOWNLOAD */
.download {
	display: flex;
	min-height: 100vh;
	background-color: #fff;
}
.download__container {
	margin: 0 auto;
	width: 100%;
	max-width: 120rem;
	display: flex;
	align-items: center;
}
.download__container picture {
	flex: 1;
	display: flex;
	justify-content: center;
	align-items: center;
	margin-right: 4.5rem;
}
.download__container picture img {
	width: 100%;
}
.download__container .content {
	flex: 2;
}
.download__container .content h1 {
	font-size: 3.5rem;
	color: var(--color-primary);
}
.download__container .content form {
	display: flex;
	margin-top: 2.5rem;
}
.download__container .content .content-apps {
	display: flex;
	margin-top: 2.5rem;
}

/* HELP */
.help__list {
	list-style: none;
}
.help__list li {
	padding: 1rem;
	border-bottom: 1px solid rgba(0, 0, 0, 0.3);
}
.help__list li h3 {
	font-size: 2.5rem;
	color: var(--color-grey-dark-1);
}
.help__list li p {
	padding: 1rem;
	font-size: 1.4rem;
}

/* TERMS */
.terms {
	display: flex;
	padding-top: 6rem;
}
.terms__container {
	width: 100%;
	max-width: 100rem;
	padding: 2.5rem;
	background-color: #fff;
	margin: 0 auto;
}